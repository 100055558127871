import React, { useContext } from "react";
import Slider from "react-slick";
import "./style.css";
import { useTranslation } from "react-i18next";
import HomeContext from "../context/HomeContext";

const TrustedBy = () => {
  const Base_Url = "https://api.jumpersksa.online/";
  const { siteInfo } = useContext(HomeContext);
  console.log(siteInfo);
  const { t, i18n } = useTranslation();

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <div className="py-20 trustedBy">
      <p className="pb-10 text-center mb-4 text-4xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl text-teal-700 dark:text-white">
        {t("Trusted By")}
      </p>
      <div className="dark:bg-gray-900">
        <div className=" mx-auto max-w-screen-xl">
          <div className="md:max-w-[1480px] m-auto  max-w-[600px]">
            <Slider {...settings} className={`sliderCont  w-max mx-auto`}>
              {siteInfo?.trustedBy?.map((partner) => (
                <div className="contOfImgSlide">
                  <img
                    src={
                      partner?.image
                        ? `${Base_Url}${partner?.image}`
                        : "https://via.placeholder.com/150"
                    }
                    alt="img"
                    className="imgSlide"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;
