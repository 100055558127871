import React, { useContext } from "react";
import Slider from "react-slick";
import FeedbackCard from "./FeedbackCard";
import { useTranslation } from "react-i18next";
import avatar from "../assets/img.jpg";
import quotationMark from "../assets/quotationMark.png";
import HomeContext from "../context/HomeContext";

const Feedback = () => {
  const siteInfo = useContext(HomeContext);
  console.log(siteInfo);
  const Base_Url = "https://api.jumpersksa.online/";
  const { t } = useTranslation();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  return (
    <div className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className="lg:max-w-[980px] m-auto max-w-[600px] px-4 md:px-0">
          <div className="py-4">
            <h2 className="py-3 text-3xl text-teal-700 font-bold">
              {t("Customer Feedback")}
            </h2>
            <p className="text-slate-700">
              {t("Various versions have evolved over the years, sometimes")}
            </p>
          </div>

          <Slider {...settings}>
            {siteInfo?.siteInfo?.feedbacks?.map((item, index) => (
              <FeedbackCard
                item={item}
                siteInfo={siteInfo?.siteInfo?.feedbacks}
                index={index}
              />
              // <div
              //   className="bg-white p-8 rounded-3xl max-h-30 shadow-md my-8 mx-2"
              //   key={index}
              // >
              //   <div className="flex justify-between">
              //     <div className="flex gap-4">
              //       <img
              //         className="rounded-full w-12 h-12"
              //         src={
              //           item?.customer_image
              //             ? `${Base_Url}${item?.customer_image}`
              //             : avatar
              //         }
              //         alt="imgOfCustomer"
              //       />
              //       <div>
              //         <h1>{item?.customer_name}</h1>
              //         <p>{item?.customer_title}</p>
              //       </div>
              //     </div>
              //     <img
              //       className="h-8 hidden md:block"
              //       src={quotationMark}
              //       alt="questionMark"
              //     />
              //   </div>
              //   <div className="py-8">
              //     <h3 className="text-lg">{item?.description}</h3>
              //   </div>
              // </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
