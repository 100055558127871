import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import HomeContext from "../context/HomeContext";

const SingleSectorComponent = () => {
  const siteInfo = useContext(HomeContext);

  const { t } = useTranslation();

  return (
    <>
      {siteInfo?.siteInfo?.sectors?.map((sector) => (
        <div className="bg-slate-100 dark:bg-gray-900">
          <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
            <div className="max-w-screen-md mx-auto">
              <h2 className="py-3 text-3xl text-teal-700 font-bold">
                {sector?.title}
              </h2>
              <div>
                <p className="text-2xl font-medium text-gray-900 dark:text-white">
                  {sector?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SingleSectorComponent;
