import React from "react";
import WhoWeAre from "../components/WhoWeAre";

const AboutUs = () => {
  return (
    <div className="mt-12">
      <WhoWeAre />
    </div>
  );
};

export default AboutUs;
