import React, { useContext } from "react";
import avatar from "../assets/img.jpg";
import quotationMark from "../assets/quotationMark.png";
import { useTranslation } from "react-i18next";
import HomeContext from "../context/HomeContext";
import "./style.css";
const FeedbackCard = ({ item, index, siteInfo }) => {
  console.log(item);
  console.log(siteInfo);
  const Base_Url = "https://api.jumpersksa.online/";
  const { t } = useTranslation();
  return (
    <div className="bg-white p-8 rounded-3xl  shadow-md my-8 mx-2" key={index}>
      <div className="flex justify-between">
        <div className="flex gap-4">
          <img
            className="rounded-full w-12 h-12"
            src={
              item?.customer_image
                ? `${Base_Url}${item?.customer_image}`
                : avatar
            }
            alt="imgOfCustomer"
          />
          <div>
            <h1>{item?.customer_name}</h1>
            <p>{item?.customer_title}</p>
          </div>
        </div>
        <img
          className="h-8 hidden md:block"
          src={quotationMark}
          alt="questionMark"
        />
      </div>
      <div className="py-8 max-h-20 overflow-auto">
        <p className="text-lg ">{item?.description}</p>
      </div>
    </div>
  );
};

export default FeedbackCard;
